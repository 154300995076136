import React from "react";
import { graphql } from "gatsby";
import { PortableText } from "@portabletext/react";
import styled from "styled-components";
import SEO from "../components/SEO";

const WritingContainer = styled.div`
    padding: 2rem;

    div > h2 {
        font-family: 'Oswald', sans-serif;
        font-size: 1.5rem;
    }
    ul {
        a {
            text-decoration: underline;
            font-weight: bold;
        }
    }

    @media (max-width: 480px) {
        padding-bottom: 20vh;
    }
`;

export default function WritingPage({data}) {
    const [{ _rawText }] = data.sanityPages.pageBuilder;
    return (
        <WritingContainer>
             <SEO
                title="Writing"
                keywords={[`J. Marcelo Borromeo`, `Filipino writer`, `UEA`, `novel`, `short story`, `fiction`, `non-fiction`]}
                />
            <h2>{data.sanityPages.title}</h2>
            <div>
                <PortableText value={_rawText} />
            </div>
        </WritingContainer>
    )
}

export const query = graphql`
    query WritingPage {
        sanityPages(title: {eq: "Writing"}) {
            title
            pageBuilder {
              ... on SanityTextContent {
                _key
                _type
                _rawText
              }
            }
          }
    }
`;